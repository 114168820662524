<template>
  <img
    :src="imgUrl"
    :width="size"
    :height="size"
    class="photo-icon"
    :title="title"
  />
</template>

<script>
export default {
  name: 'HMPhotoIcon',
  props: {
    instructor: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: '25',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    imgUrl() {
      return (
        this.instructor.image?.thumbnail ||
        this.instructor.image?.embedded_image_url ||
        this.instructor.image ||
        this.instructor.profile_photo ||
        this.instructor.community_profile_photo ||
        '/assets/images/default-instructor-photo.png'
      );
    },
  },
};
</script>

<style scoped>
.photo-icon {
  vertical-align: center;
  object-fit: cover;
  border-radius: 50%;
}
</style>
